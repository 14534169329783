import { useMiddleware } from "@/store/middleware";
import { isSpecialSubdomain } from "~/utils/helper";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuth();
  const config = useRuntimeConfig();
  const middleware = useMiddleware();

  //Check account status
  const account = ref<{ account_status: string }>({ account_status: "" });
  if (!isSpecialSubdomain(auth?.subdomain)) {
    try {
      if (!middleware.account || middleware.accountDataIsStale) {
        await middleware.accountRefetch();
      }
      account.value = middleware.account;
    } catch (e) {
      return navigateTo(`${config.public.primaryURL}/switch`, {
        external: true,
      });
    }
  }

  // Can not access unless in a subdomain
  if (
    isSpecialSubdomain(auth?.subdomain) &&
    account.value?.account_status === "ONBOARDING"
  ) {
    return navigateTo(`${config.public.primaryURL}/onboarding`);
  }

  // Can not access unless account is onboarding
  if (account.value?.account_status === "ONBOARDING") {
    // Can not access but select pages
    switch (to.path) {
      case "/onboarding/payment":
      case "/onboarding/contacts":
      case "/onboarding/agreement":
        return;
    }
  } else {
    return navigateTo("/hub/sessions");
  }
});
